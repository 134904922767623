<template>
  <div class="show-answer m-md-2 m-1" v-if="pageData">
    <b-row class="page-header gap-3 w-100 m-0" align-h="between">
      <b-col cols="auto d-flex align-items-center">
        <b-avatar size="56" src="" />
        <div class="user-info ml-2 d-flex flex-column">
          <h2 class="m-0">
            {{
              `${pageData.enrollment.student.user.first_name}
                      ${pageData.enrollment.student.user.last_name}`
            }}
          </h2>
          <p class="m-0">{{ submissionDate }}</p>
        </div>
      </b-col>
      <b-col cols="auto d-flex align-items-center">
        <p class="submission-name m-0">{{ `${$t('g.submission')} ${submissionNumber}` }}</p>
      </b-col>
    </b-row>
    <b-row class="submission-card my-3 mx-md-3 mx-2" align-h="between">
      <b-col cols="12 mb-2 px-0">
        <div
          class="card-header d-flex justify-content-between align-items-center flex-wrap p-2"
        >
          <div class="d-flex align-items-center">
            <img width="30px" height="36px" :src="selectedType" alt="pdf" />
            <p class="answer-title mb-0 ml-1">
              {{ $t("g.views/classrooms/show_submission/answer_title") }}
              {{ currentPage }}
            </p>
          </div>
          <b-pagination
            per-page="1"
            limit="4"
            v-model="currentPage"
            :total-rows="rows"
            size="sm"
          ></b-pagination>
        </div>
        <b-embed
          style="background-color: #fff"
          type="iframe"
          aspect="16by9"
          :src="answerValue"
          allowfullscreen
        ></b-embed>
      </b-col>
      <b-col class="w-100">
        <validation-observer ref="submissionForm">
          <b-form>
            <!-- Start Comment Input -->
            <b-col cols="12" class="mb-3 px-0">
              <validation-provider>
                <b-form-textarea
                  no-resize
                  rows="4"
                  v-model="commentValue"
                  :placeholder="
                    $t('g.views/classrooms/show_submission/comment_placeholder')
                  "
                ></b-form-textarea>
              </validation-provider>
            </b-col>
            <!-- End Comment Input -->
            <b-row class="justify-content-between px-1">
              <b-col cols="auto mb-2 px-0">
                <!-- Start Score Input -->
                <validation-provider
                  #default="{ errors }"
                  name="score"
                  rules="required||integer"
                >
                  <b-form-input
                    class="score-input mb-1"
                    type="number"
                    :placeholder="
                      pageData.submission_grade
                        ? pageData.submission_grade.score.toString()
                        : $t(
                            'g.views/classrooms/show_submission/score_placeholder'
                          )
                    "
                    v-model="scoreValue"
                    ref="scoreInput"
                  />
                  <small class="">
                    {{
                      $t("g.views/classrooms/show_submission/score_helper_text")
                    }}
                    {{ pageData.assignment.max_score }}
                  </small>
                  <br />
                  <small class="text-danger">
                    {{ errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0] }}
                  </small>
                </validation-provider>
              </b-col>
              <!-- End Score Input -->

              <!-- Start Submit Button -->
              <b-col cols="auto align-items-end px-0  ">
                <b-button
                  @click="validation"
                  :disabled="is_loading || overlay_loading"
                  class="submit-btn"
                >
                  {{ $t("g.views/classrooms/show_submission/send_button") }}
                </b-button>
                <!-- End Submit Button -->
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <!-- overlay loading -->
      <overlay-component :isLoading="overlay_loading" />
      <!-- overlay loading -->
    </b-row>
  </div>
</template>

<script>
import OverlayComponent from "@/components/shared/OverlayComponent";
import { required, integer } from "@core/utils/validations/validations";
import { ValidationObserver, ValidationProvider } from "vee-validate";

import {
  BAvatar,
  BRow,
  BCol,
  BContainer,
  BEmbed,
  BFormTextarea,
  BForm,
  BFormInput,
  BButton,
  BPagination,
} from "bootstrap-vue";
export default {
  components: {
    BAvatar,
    BRow,
    BCol,
    BEmbed,
    BContainer,
    BFormTextarea,
    BFormInput,
    BButton,
    BForm,
    OverlayComponent,
    BPagination,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      is_loading: true,
      overlay_loading: false,
      submissionId: null,
      perPage: 1,
      currentPage: 1,
      submissionNumber: null,
      pageData: null,
      scoreValue: null,
      commentValue: null,
      pdfIcon: require("@/assets/images/icons/Group.png"),
      imageIcon: require("@/assets/images/icons/photo.png"),
      videoIcon: require("@/assets/images/icons/youtube.png"),
    };
  },
  beforeMount() {
    if (this.$route.params) {
      this.submissionId = this.$route.params.submission_id;
      this.submissionNumber = this.$route.params.submission_name;
      this.getSubmissionsData(
        this.$route.params.enrollment_id,
        this.$route.params.assignment_id,
        this.$route.params.submission_id
      );
    } else {
      this.$router.push("/");
    }
  },
  computed: {
    rows() {
      return this.pageData?.answer_docs?.length;
    },
    answerValue() {
      return this.pageData?.answer_docs[this.currentPage - 1].path;
    },
    submissionDate() {
      const originalDate = new Date(this.pageData?.submit_date);
      const options = {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "UTC",
      };
      return originalDate.toLocaleString("en-US", options);
    },
    selectedType() {
      if (
        this.pageData?.answer_docs[this.currentPage - 1].type.includes("file")
      ) {
        return this.pdfIcon;
      } else if (
        this.pageData?.answer_docs[this.currentPage - 1].type.includes("video")
      ) {
        return this.videoIcon;
      } else if (
        this.pageData?.answer_docs[this.currentPage - 1].type.includes("image")
      ) {
        return this.imageIcon;
      } else {
        return "";
      }
    },
  },
  methods: {
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    async getSubmissionsData(enrollment_id, assignment_id, submission_id) {
      try {
        this.is_loading = true;
        const res = await this.$http.get(
          `/teacher/enrollments/${enrollment_id}/assignments/${assignment_id}/submissions/${submission_id}`
        );
        this.pageData = res.data.data;
        this.scoreValue = this.pageData.submission_grade.score;
        this.commentValue = this.pageData.comments[0].text;
        console.log(this.pageData.comments[0].id);
      } catch (error) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } finally {
        this.is_loading = false;
      }
    },
    async submit() {
      try {
        this.overlay_loading = true;
        const formData = new FormData();
        formData.append("submission_id", this.submissionId);
        formData.append("score", parseInt(this.scoreValue));
        const res = await this.$http.post(
          "/teacher/submission-grades",
          formData
        );
        if (res.status === 200 || res.status === 201) {
          this.commentValue && (await this.submitComment());
          this.makeToast("success", res.data.message, res.data.message);
          this.$router.push({
            name: "answers",
            params: { id: this.$route.params.assignment_id },
            query: { course_id: this.$route.query.course_id },
          });
        }
      } catch (error) {
        this.showError(
          error,
          "classrooms/show_submission/can_not_submit_score"
        );
      } finally {
        this.overlay_loading = false;
      }
    },
    async submitComment() {
      try {
        this.overlay_loading = true;
        const formData = new FormData();
        formData.append("text", this.commentValue);
        await this.$http.post(
          `/teacher/submissions/${this.submissionId}/teacher-comments`,
          formData
        );
      } catch (error) {
        this.showError(
          error,
          "classrooms/show_submission/can_not_submit_comment"
        );
      } finally {
        this.overlay_loading = false;
      }
    },
    async updateComment() {
      try {
        this.overlay_loading = true;
        const formData = new FormData();
        formData.append("text", this.commentValue);
        await this.$http.post(
          `/teacher/submissions/${this.submissionId}/teacher-comments/${this.pageData.comments[0].id}?_method=put`,
          formData
        );
      } catch (error) {
        this.showError(
          error,
          "classrooms/show_submission/can_not_submit_comment"
        );
      } finally {
        this.overlay_loading = false;
      }
    },
    async updateScore() {
      try {
        this.overlay_loading = true;
        const formData = new FormData();
        formData.append("submission_id", this.submissionId);
        formData.append("score", parseInt(this.scoreValue));
        const res = await this.$http.post(
          `/teacher/submission-grades/${this.pageData.submission_grade.id}?_method=put`,
          formData
        );
        if (res.status === 200 || res.status === 201) {
          this.commentValue && (await this.updateComment());
          this.makeToast("success", res.data.message, res.data.message);
          this.$router.push({
            name: "answers",
            params: { id: this.$route.params.assignment_id },
            query: { course_id: this.$route.query.course_id },
          });
        }
      } catch (error) {
        this.showError(
          error,
          "classrooms/show_submission/can_not_submit_score"
        );
      } finally {
        this.overlay_loading = false;
      }
    },
    showError(error, defaultError) {
      if (error.response?.data?.errors) {
        for (const key in error.response?.data?.errors) {
          this.makeToast(
            "danger",
            error.response.data.errors[key][0],
            this.$t("g.send.errorTitle")
          );
        }
      } else if (error.response?.data?.message) {
        this.makeToast(
          "danger",
          error.response?.data?.message,
          this.$t("g.send.errorTitle")
        );
      } else {
        this.makeToast(
          "danger",
          this.$t(`g.${defaultError}`),
          this.$t("g.send.errorTitle")
        );
      }
    },
    validation() {
      this.$refs.submissionForm.validate().then((success) => {
        if (success) {
          if (this.scoreValue === null) {
            this.makeToast(
              "danger",
              this.$t("g.alert.The_score_field_is_required"),
              this.$t("g.send.errorTitle")
            );
          } else if (+this.scoreValue > +this.pageData.assignment.max_score) {
            this.makeToast(
              "danger",
              `${this.$t("g.alert.The_score_must_be_smaller_than")} ${
                this.pageData.assignment.max_score
              }`,
              this.$t("g.send.errorTitle")
            );
          } else {
            if (this.pageData.submission_grade?.score) {
              this.updateScore();
            } else {
              this.submit();
            }
          }
        }
      });
    },
    validateInput() {
      if (this.scoreValue > this.pageData.assignment.max_score) {
        this.scoreValue = "";
      }
    },
  },
  watch: {
    scoreValue(newValue) {
      if (newValue > parseInt(this.pageData.assignment.max_score)) {
        this.scoreValue = "";
      }
    },
  },
};
</script>

<style lang="scss">
@import "./showSubmission.scss";
</style>
